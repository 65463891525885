:root {
    --BLACK: #222222;
    --BLACK-2: #212121;
    --BLACK-3: #292929;
    --BLACK-4: #2c2c2c;
    --BLACK-5: #767676;
    --RED: #e64545;
    --LIGHT-GRAY: #f9f9f9;
    --MID-GRAY: #dcdcdc;

    --padding-block: clamp(2rem, 12.5vi + -4rem, 4rem);
    --padding-inline: 20px;
    --max-content-width: 1200px;
    --CONTAINER-COLUMNS: [full-width-start] minmax(var(--padding-inline), 1fr) [content-start right-bleed-start] min(100% - (var(--padding-inline) * 2), var(--max-content-width)) [content-end] minmax(var(--padding-inline), 1fr) [full-width-end right-bleed-end];
    --PAGE-COLUMNS: [page-width-start] 100% [page-width-end];
    --PAGE-ROWS: [site-header-start] auto [site-header-end site-main-start] 1fr [site-main-end site-footer-start] auto [site-footer-end];
    --default-list-style: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'/%3E");
    --viewport-min-height: calc(var(--vh, 1vh) * 100);
    --touch-min-size: 44px;

    --DURATION: .3s;
}
