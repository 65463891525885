.site-header__menu {
    @media (--max-mobile) {
        grid-column: 1/-1;

        body:not(.menu-is-active) & {
            display: none;
        }

        .menu-is-active & {
            transition: transform var(--DURATION);
            transform: translateY(0) scale(1);

            @starting-style {
                transform: translateY(8px) scale(.95);
            }
        }
    }

    @media (--min-desktop) {
        justify-self: end;
    }

    ul {
        display: flex;

        @media (--max-mobile) {
            align-items: center;
            flex-direction: column;
            row-gap: 16px;
        }

        @media (--min-desktop) {
            column-gap: 40px;
        }
    }

    li {
        .menu-is-active & {
            transition-delay: var(--delay);
            transition-duration: var(--duration);
            transition-property: transform, opacity;
            transform: translateY(0) scale(1);

            &:nth-child(1) {
                --delay: 0;
                --duration: .3s;
            }
            &:nth-child(2) {
                --delay: .025s;
                --duration: .2975s;
            }
            &:nth-child(3) {
                --delay: .05s;
                --duration: .295s;
            }
            &:nth-child(4) {
                --delay: .075s;
                --duration: .2925s;
            }
            &:nth-child(5) {
                --delay: .1s;
                --duration: .29s;
            }

            @starting-style {
                opacity: 0;
                transform: translateY(8px) scale(.95);
            }
        }
    }

    a {
        display: inline-flex;
        align-items: center;
        min-height: var(--touch-min-size);
        transition: color var(--DURATION);
        white-space: nowrap;
        text-decoration: none;
        text-transform: uppercase;
        color: var(--BLACK);
        padding-inline: 4px;
        @mixin focus-visible--outline;

        @media (--max-mobile) {
            font-size: 24px;
            line-height: 2;
        }

        @media (--min-desktop) {
            font-size: 16px;
        }

        &:hover, &[aria-current="page"] {
            color: var(--RED);
        }
    }
}
