.article {
    padding-block: var(--padding-block);

    &__wrapper {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        text-align: left;
        @media (--max-mobile) {
            row-gap: 60px;
        }

        @media (--min-desktop) {
            row-gap: 56px;
        }

        > h1 {
            font-size: clamp(2rem, 2.47cqi + 1.42rem, 3rem);
            text-transform: uppercase;
        }
    }
}
