.site-footer {
    background-color: var(--BLACK-3);
    padding-block: var(--padding-block) calc(var(--padding-block) / 2);

    &__wrapper {
        display: grid;
        column-gap: 60px;

        @media (768px <= width <= 1279px) {
            max-width: 608px;
            justify-self: center;
            grid-template-columns: 1fr auto;
        }

        @media (width >= 1280px) {
            grid-template-columns: repeat(3, 1fr);
        }
    }
}
