.site-footer__text {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    color: white;
    row-gap: 32px;

    @media (768px <= width <= 1279px) {
        align-items: center;
        margin-top: 60px;
        text-align: center;
        grid-column: 1/-1;
    }

    a {
        text-decoration: none;
        color: inherit;
        @mixin hover--opacity;
        @mixin focus-visible--outline;
    }
}
