:--plain-list {
    padding-left: 0;
    list-style-image: var(--default-list-style);
    margin-block: var(--plain-list__margin-block, 0);
}

.ghost-link {
    position: absolute;
    color: var(--ghost-link-color, inherit);
    inset: 0;
    @mixin focus-visible--outline;
}
