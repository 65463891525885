/* https://medium.com/@matuzo/writing-css-with-accessibility-in-mind-8514a0007939 */
.visually-hidden,
.sr-only {
    @mixin visually-hidden;
}

.screenreader-text {
    position: absolute;
    top: auto;
    left: -999px;
    width: 1px;
    height: 1px;

    &:focus-visible {
        font-size: 16px;
        font-weight: bold;
        position: fixed;
        z-index: 9999;
        top: 8px;
        left: 50%;
        display: inline-block;
        width: auto;
        height: auto;
        margin: auto;
        padding: 8px 16px;
        text-align: center;
        color: white;
        outline: 2px solid var(--BLACK);
        outline-offset: 2px;
        background-color: var(--RED);
        translate: -50% 0;
    }
}
