.site-header {
    z-index: 1;
    display: grid;
    align-items: center;
    padding-inline: var(--padding-inline);
    --grid-column: full-width;

    @media (--max-mobile) {
        background-color: white;
        box-shadow: 0 4px 8px rgba(0, 0, 0, .15);
        grid-template-columns: 1fr repeat(2, var(--touch-min-size));
        grid-template-areas: "headerLogo headerLogin headerToggle";
        column-gap: 8px;
        padding-block: 12px;

        .menu-is-active & {
            position: relative;
            z-index: 1;
            overflow-y: auto;
            height: 100dvh;
            background-color: var(--LIGHT-GRAY);
            scrollbar-gutter: stable;
            grid-template-rows: min-content 1fr;
            row-gap: 10px;
            -webkit-overflow-scrolling: touch;
        }


    }

    @media (--min-desktop) {
        width: min(100%, 1920px);
        padding-block: 20px;
        grid-template-columns: auto 1fr auto;
        column-gap: 48px;
        justify-self: center;
    }
}

.menu-is-active {
    overflow: hidden;
}
