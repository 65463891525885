.privacy-popup {
    position: fixed;
    z-index: 9999;
    bottom: 50px;
    left: 0;
    display: flex;
    visibility: var(--privacy-pop-up__visibility, hidden);
    align-items: center;
    flex-direction: column;
    width: min(600px, calc(100% - 32px));
    text-align: left;
    color: var(--BLACK);
    border-radius: 16px;
    background-color: white;
    box-shadow: 0 0 5px 1px rgba(0, 0, 0, .2);
    margin-block: 16px;
    margin-inline: 16px;
    filter: opacity(var(--privacy-pop-up__opacity, 0));
    padding-inline: 16px;
    padding-block: 16px 8px;
    row-gap: 8px;

    &.is-visible {
        --privacy-pop-up__visibility: visible;
        --privacy-pop-up__opacity: 1;
    }

    > * {
        margin-block: 0;
    }

    > p {
        color: currentColor;
        inline-size: 100%;
    }
    a {
        color: var(--RED);
		@mixin hover--reverse-underline;
		@mixin focus-visible--outline;
    }

    :where(p, a) {
        font-size: 16px;
        font-weight: 300;
    }
	&__button {
		&--accept {
            font-weight: 700;
            padding-right: 24px;
            padding-left: 24px;
            cursor: pointer;
            text-transform: uppercase;
			border: none;
			background-color: white;
			@mixin hover--opacity;
			@mixin focus-visible--outline;
		}
	}
}
