.contact-us {
    display: grid;
    padding-block: var(--padding-block);
    row-gap: 36px;

    &.has-bgc {
        background-color: var(--LIGHT-GRAY);
        box-shadow: 0 0 0 100vmax var(--LIGHT-GRAY);
        clip-path: inset(0 -100vmax);
    }

    @media (--min-desktop) {
        column-gap: 72px;
        grid-template-columns: auto 1fr;
    }

    h1 {
        font-size: clamp(2rem, 2.47cqi + 1.42rem, 3rem);
        text-transform: uppercase;
        grid-column: 1/-1;
    }

    h2 {
        font-size: clamp(1.25rem, 1.23vi + 0.96rem, 1.75rem);
        text-transform: uppercase;
        grid-column: 1/-1;
    }

    &__info {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        row-gap: 32px;

        div {
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            row-gap: 2px;
        }

        dt {
            font-size: 18px;
            font-weight: 600;
            text-transform: uppercase;
        }

        a {
            text-decoration: none;
            color: inherit;
            @mixin hover--opacity;
            @mixin focus-visible--outline;
        }
    }
}
