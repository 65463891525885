.security-cards {
    display: flex;
    flex-direction: column;
    width: 100%;
    row-gap: 16px;

    @media (--min-desktop) {
        position: relative;
    }

    & &__list {
        display: flex;
        align-items: center;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        max-width: 100%;
        list-style: none;
        border: 1px solid var(--MID-GRAY);
        background-color: white;
        row-gap: 48px;
        column-gap: 60px;
        padding-block: clamp(2.25rem, 1.23vw + 1.96rem, 2.75rem);
        padding-inline: clamp(1.88rem, 5.86vw + 0.5rem, 4.25rem);

        @media (width <= 767px) {
            column-gap: 24px;
            row-gap: 8px;
        }

        @media (--min-desktop) {
            position: sticky;
            top: 32px;
        }
    }

    &__item {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        @mixin hover--opacity;
    }

    &__link {
        position: absolute;
        inset: 0;
        @mixin focus-visible--outline;
        --outline-color: var(--BLACK);
    }

    &__img {
        @media (width <= 767px) {
            width: var(--width);

            &--pci30 {
                --width: 80px;
            }

            &--aicpa-1,
            &--aicpa-2 {
                --width: 54px;
            }

            &--digicert {
                --width: 88px;
            }

            &--ei3pa {
                --width: 86px;
            }

            &--fcra {
                --width: 94px;
            }
        }
    }
}
