.site-header__login {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-height: var(--touch-min-size);
    text-decoration: none;

    @media (--max-mobile) {
        transform: translateX(12px);
        color: var(--RED);
        grid-area: headerLogin;
    }

    @media (--min-desktop) {
        font-size: 16px;
        user-select: none;
        text-transform: uppercase;
        color: white;
        background-color: var(--RED);
        padding-inline: var(--padding-inline);
        @mixin hover--opacity;
        @mixin focus-visible--outline;
        --outline-color: var(--BLACK);
    }

    svg {
        @media (--min-desktop) {
            display: none;
        }
    }

    span {
        @media (--max-mobile) {
            @mixin visually-hidden;
        }
    }
}
