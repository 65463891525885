@define-mixin hover--reverse-underline {
    @media (hover: hover) {
        & {
            text-decoration: underline;
            text-decoration-color: currentColor;
            text-decoration-thickness: 1px;
            transition: text-decoration-color .3s;

            &:hover:not(:focus) {
                text-decoration-color: transparent;
            }
        }
    }
}
