.cta {
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: 896px;
    text-align: center;
    padding-block: var(--padding-block);
    justify-self: center;
    row-gap: 32px;

    h2 {
        font-size: clamp(1.25rem, 1.23vi + 0.96rem, 1.75rem);
    }

    .tagline {
        font-size: clamp(1rem, 0.62vi + 0.86rem, 1.25rem);
        font-weight: 600;
        margin-top: -32px;
    }

    p:not([class]) {
        font-weight: 300;
        color: var(--BLACK-4);
    }
}
