:where(body) {
    display: grid;
    width: 100%;
    min-width: 320px;
    min-height: var(--viewport-min-height);
    color: var(--BLACK);
    grid-template-columns: var(--PAGE-COLUMNS);
    grid-template-rows: var(--PAGE-ROWS);
    text-rendering: optimizeSpeed;
}

:where(.site-main, .site-footer) {
    display: grid;
    align-items: start;
    width: 100%;
    grid-column: var(--PAGE-COLUMNS);
    grid-template-columns: var(--CONTAINER-COLUMNS);
    grid-auto-rows: min-content;

    > * {
        grid-column: var(--grid-column, content);
    }
}

:where(.site-header) {
    grid-row: site-header;
}

:where(.site-main) {
    background-color: white;
    grid-row: site-main;
}

:where(.site-footer) {
    grid-row: site-footer;
}


:where(input, button, textarea, select) {
    font: inherit;
}

.plain-list {
    padding-left: 0;
    list-style-image: var(--default-list-style);
    margin-block: var(--plain-list__margin-block, 0);
}

:where(picture) {
    display: block;
    max-width: 100%;
    height: auto;
}

:where(h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd) {
    margin: 0;
}

:where(h1,h2,h3,h4) {
    line-height: 1.2;
}
