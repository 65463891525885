.site-header {
    [data-toggle-menu=""] {
        @media (--min-desktop) {
            display: none;
        }

        @media (--max-mobile) {
            position: relative;
            z-index: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            width: var(--touch-min-size);
            height: var(--touch-min-size);
            padding: 0;
            transform: translateX(12px);
            color: currentColor;
            border: none;
            grid-area: headerToggle;
        }

        [aria-hidden="true"] {
            pointer-events: none;
            @media (width <= 1023px) {
                display: inline-flex;
                align-items: center;
                align-self: center;
                flex-direction: column;
                justify-content: center;
                cursor: pointer;
                color: currentColor;
                row-gap: var(--toggle__row-gap);
                --toggle-rotate: 45deg;
                --toggle__block-size: 2px;
                --toggle__inline-size: 20px;
                --toggle__row-gap: 4px;
                --toggle__translate: 6px;
                @mixin focus-visible--outline;

                &:hover {
                    transition: color var(--DURATION);
                }
            }
        }

        span {
            position: relative;
            display: block;
            transition-duration: var(--DURATION);
            border-radius: 2px;
            background-color: currentColor;
            inline-size: var(--toggle__inline-size);
            block-size: var(--toggle__block-size);

            &:where(:not(:first-child,:last-child)) {
                transition-property: scale;
            }

            &:where(:first-child,:last-child) {
                transition-property: translate, rotate, scale;
                transform-origin: center;
            }
        }

        &[aria-expanded="true"] {

            span {
                &:where(:not(:first-child,:last-child)) {
                    color: rgba(255, 255, 255, 0);
                    scale: 1% 100%;
                }

                &:where(:first-child,:last-child) {
                    rotate: calc(var(--toggle-rotate) * var(--calculate-rotate, 1));
                    translate: 0 calc(var(--toggle__translate) * var(--calculate-translate, 1));
                    scale: -1 1;
                }

                &:where(:first-child) {
                    --calculate-rotate: -1;
                }

                &:where(:last-child) {
                    --calculate-translate: -1;
                }
            }
        }
    }
}
