#hsFormContainerDL {
    width: 100%;
    container-name: form;
    container-type: inline-size;

    form {
        font-family: var(--FONT);
        font-size: var(--fz);
        font-weight: 400;
        display: grid;
        align-items: start;
        width: 100%;
        color: var(--BLACK);
        grid-gap: var(--gg);
        grid-template-columns: var(--gtc);
        grid-template-areas: var(--gta);
    }

    .hs-firstname {
        grid-area: ffn;
    }

    .hs-lastname {
        grid-area: fln;
    }

    .hs-area_of_interest_complytraq {
        grid-area: fai;
    }

    .hs-email {
        grid-area: fe;
    }

    .hs-company {
        grid-area: fcn;
    }

    .hs-messagepafcontact {
        height: 100%;
        grid-area: fm;

        .input {
            height: 100%;

            textarea {
                height: 100%;
            }
        }
    }

    .hs_submit {
        grid-column: 1/-1;
    }

    .form-columns-1 {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        width: 100%;
        max-width: none;

        > * {
            width: 100%;
        }

        .input,
        .input > *:not(.visually-hidden) {
            display: flex;
            width: 100% !important;
            margin: 0;
        }
    }

    .form-columns-2 {
        display: flex;
        @media (min-width: 768px) {
            flex-direction: row;
            width: calc(100% + 20px);
            max-width: none;
            margin-right: -10px;
            margin-left: -10px;
        }
        @media (max-width: 767px) {
            align-items: flex-start;
            flex-direction: column;
            width: 100%;
            max-width: none;
        }

        .input {
            @media (min-width: 768px) {
                margin-right: 0 !important;
            }
            @media (max-width: 767px) {
                width: 100% !important;
                margin: 0;
            }
        }

        > * {
            @media (min-width: 768px) {
                width: calc(50% - 20px) !important;
                margin-right: 10px;
                margin-left: 10px;
            }
            @media (max-width: 767px) {
                width: 100% !important;
                margin: 0;
            }

            &:nth-child(1) {
                @media (max-width: 767px) {
                    margin-bottom: 15px;
                }
            }
        }
    }

    fieldset {
        margin: 0;
        padding: 0;
        border: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }

    legend {
        font-family: var(--FONT);
        font-size: 16px;
        width: 100%;
        margin-bottom: 20px;
        color: var(--BLACK);
    }

    input[type='email'],
    input[type='tel'],
    input[type='text'],
    select {
        display: flex;
        align-items: center;
        box-sizing: inherit;
        width: 100%;
        max-width: none;
        height: var(--h);
        padding-right: var(--p);
        padding-left: var(--p);
        border: 1px solid var(--MID-GRAY);
        border-radius: 0;
        outline: transparent solid 2px;
        outline-offset: 0;
        background-color: white;
        background-image: none;
        box-shadow: none;
        --p: 16px;
        --h: 44px;
        appearance: none;

        &:focus {
            outline-color: var(--BLACK);
        }
    }

    textarea {
        display: flex;
        align-items: center;
        width: 100%;
        max-width: none;
        height: var(--h);
        padding: var(--p);
        resize: vertical;
        border: 1px solid var(--MID-GRAY);
        border-radius: 0;
        outline: transparent solid 2px;
        outline-offset: 0;
        background-color: white;
        background-image: none;
        box-shadow: none;
        appearance: none;
        @media (min-width: 768px) {
            --p: 16px;
            --h: 160px;
        }
        @media (max-width: 767px) {
            --p: 16px;
            --h: 100px;
        }

        &:focus {
            outline-color: var(--BLACK);
        }
    }

    input,
    textarea {
        font-size: inherit !important;

        &::-webkit-input-placeholder,
        &::-webkit-input-placeholder {
            color: var(--BLACK);
        }

        &::-moz-placeholder,
        &::-moz-placeholder {
            color: var(--BLACK);
        }

        &.error {
            border-color: var(--RED);
        }
    }

    .contact-form__submit {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 170px;
        height: 44px;
        padding: 0;
        cursor: pointer;
        transition: opacity 0.3s;
        text-transform: uppercase;
        color: var(--c);
        border: none;
        border-radius: 0;
        outline: transparent solid 2px;
        outline-offset: 0;
        background-color: var(--RED);
        padding-inline: 16px;
        --c: white;
        appearance: none;
        @media (min-width: 768px) {
            margin-left: auto;
        }
        @media (max-width: 767px) {
            justify-content: center;
            width: 100%;
        }

        &:hover {
            opacity: 0.7;
        }

        &:focus {
            outline-color: var(--BLACK);
        }
    }

    .hs-error-msgs {
        padding-left: 0;
        list-style: none;

        label {
            font-size: 16px;
            font-weight: normal;
            padding-top: 5px;
            list-style: none;
            color: var(--RED) !important;
        }
    }

    .hs-form-field > label {
        display: flex !important;
        margin-bottom: 0;

        span {
            font-size: 16px;
        }
    }

    .actions {
        margin: 0;
        padding: 0;
    }


    .input:has(select) {
        position: relative;

        &::before {
            position: absolute;
            z-index: 1;
            top: 50%;
            right: 16px;
            margin-top: -3px;
            content: "";
            border: 6px solid transparent;
            border-top-color: var(--BLACK);
            border-radius: 4px;
        }

        &::after {
            position: absolute;
            z-index: 1;
            top: 50%;
            right: 16px;
            margin-top: -5px;
            content: "";
            border: 6px solid transparent;
            border-top-color: white;
        }
    }
}

@container (min-width: 640px) {
    #hsFormContainerDL form {
        --gtc: repeat(2, 1fr);
        --gg: 16px;
        --fz: 18px;
        --gta: "ffn fln" "fai fm" "fe fm" "fcn fm";
    }
}

@container (max-width: 639px) {
    #hsFormContainerDL form {
        --gtc: 100%;
        --gg: 24px;
        --fz: 16px;
        --gta: "ffn" "fln" "fai" "fe" "fcn" "fm";
    }
}
