.site-footer__copyright {
    display: flex;
    justify-content: center;
    margin-top: calc(var(--padding-block) * 1.25);
    text-align: center;
    color: white;
    background-color: var(--BLACK-2);
    box-shadow: 0 0 0 100vmax var(--BLACK-2);
    grid-column: 1/-1;
    clip-path: inset(calc(var(--padding-block) / -2) -100vmax);

    small {
        font-size: 14px;
    }
}
