.site-footer__menu {
    @media (width <= 767px) {
        margin-block: 60px;
    }
    @media (width >= 780px) {
        margin-top: 56px;
    }

    ul {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        color: white;

        a {
            line-height: 2;
            text-decoration: none;
            color: inherit;
            @mixin hover--opacity;
            @mixin focus-visible--outline;

            &[aria-current="page"] {
                text-decoration: underline;
                text-decoration-thickness: 1px;
            }
        }
    }
}
