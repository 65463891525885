.advantages {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-block: var(--padding-block);
    row-gap: 48px;
    --ct-primary: #DA564B;
    --ct-secondary: #F9F9F9;
    --ct-text-dark: #333333;
    --ct-text-light: #222222;
    --ct-heading: #292929;
    --ct-box-shadow: #0001011C;

    h2 {
        font-size: 28px;
        font-weight: 700;
        color: var(--ct-text-dark);
        justify-self: center;
    }

    &__availability {
        display: inline-flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: center;
        background-color: white;
        box-shadow: 0 2px 14px var(--ct-box-shadow);
        padding-inline: 32px;
        padding-block: 8px;
        column-gap: 16px;
        row-gap: 8px;
        justify-self: center;

        h3 {
            font-size: 19px;
            font-weight: 600;
            color: var(--ct-text-light);
        }

        ul {
            display: inline-flex;
            flex-wrap: wrap;
            justify-content: center;
            padding-left: 0;
            list-style-type: none;
            margin-block: 0;
            column-gap: 24px;
            row-gap: 8px;

            li {
                font-size: 19px;
                font-weight: 400;
                display: inline-flex;
                align-items: center;
                color: var(--ct-text-light);
                column-gap: 8px;

                &::before {
                    display: block;
                    flex-shrink: 0;
                    width: 22px;
                    height: 22px;
                    content: "";
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='23.319' height='23.319' viewBox='0 0 23.319 23.319'%3E%3Cpath fill='%237bc043' d='m16.879 9-.9-.9a.423.423 0 0 0-.6 0l-5.391 5.39-2.564-2.585a.425.425 0 0 0-.6 0l-.9.9a.423.423 0 0 0 0 .6l3.761 3.788a.424.424 0 0 0 .6 0l6.6-6.6A.422.422 0 0 0 16.879 9Zm-5.22-9a11.659 11.659 0 1 0 11.66 11.659A11.659 11.659 0 0 0 11.659 0Zm0 20.628a8.969 8.969 0 1 1 8.969-8.969 8.97 8.97 0 0 1-8.969 8.969Z'/%3E%3C/svg%3E");
                    background-size: contain;
                }
            }
        }
    }

    .ct-table {
        display: flex;
        overflow-x: auto;
        width: 100%;
        padding-bottom: 20px;

        @media (width <= 820px) {
            overflow-y: hidden;
            width: 100vw;
            padding-inline: var(--padding-inline);
            margin-inline: calc(var(--padding-inline) * -1);
            contain: paint;
        }

        table {
            width: 100%;
        }

        thead {
            th {
                height: 52px;
                padding-inline: 6px;
            }

            tr:first-child th:first-child {
                font-size: 20px;
                font-weight: 700;
                position: relative;
                color: var(--ct-heading);

                &::before {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 1px;
                    content: "";
                    background-color: var(--ct-primary);
                }
            }

            tr:first-child th:last-child {
                font-size: 17px;
                font-weight: 700;
                text-transform: uppercase;
                color: white;
                border-right: 2px solid var(--ct-primary);
                border-left: 2px solid var(--ct-primary);
                background-color: var(--ct-primary);
            }

            tr:last-child {
                border-bottom: 1px solid #E0E0E0;

                th {
                    font-size: 16px;
                    font-weight: 500;
                    min-width: 192px;
                    color: var(--ct-text-dark);

                    &:first-child {
                        border-right: 1px solid #E0E0E0;
                    }

                    &:nth-child(3) {
                        border-right: 1px solid #E0E0E0;
                        border-left: 2px solid var(--ct-primary);
                        background-color: var(--ct-secondary);
                    }

                    &:nth-child(4) {
                        border-right: 2px solid var(--ct-primary);
                        background-color: var(--ct-secondary);
                    }

                    > span {
                        line-height: 1;
                        display: flex;
                        align-items: center;
                        flex-direction: column;
                        text-align: center;
                    }
                }
            }
        }

        tbody {
            tr {
                border-bottom: 1px solid #E0E0E0;

                &:last-child td:nth-last-child(-n+2) {
                    border-bottom: 2px solid var(--ct-primary);
                }
            }

            [data-icon="ct-check"] {
                display: block;
                width: clamp(1.5rem, 3.39vi + 0.71rem, 2.88rem);
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='46' height='36' viewBox='0 0 46 36'%3E%3Cpath fill='%236a5d5d' d='M1.19 20.98a41.178 41.178 0 0 1 10.618 13.912 2.542 2.542 0 0 0 4.474.168c4.589-7.831 15.234-24.072 29.251-33.184a1.016 1.016 0 0 0-.919-1.8 50.235 50.235 0 0 0-11.857 6.588c-5.792 4.283-11.5 8.408-17.963 17.535 0 0-4.286-9.5-10.671-9.37a3.9 3.9 0 0 0-3.961 2.7A3.156 3.156 0 0 0 1.19 20.98'/%3E%3C/svg%3E");
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
                aspect-ratio: 46/36;

                &:has(+ small) {
                    width: clamp(1.5rem, 3.39vi + 0.71rem, 2rem);
                }

                & + small {
                    font-size: 15px;
                    font-weight: 600;
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    margin-top: 6px;
                    text-align: center;
                    color: var(--ct-text-dark);
                }
            }

            td:nth-child(3) {
                border-right: 1px solid #E0E0E0;
                border-left: 2px solid var(--ct-primary);
                background-color: var(--ct-secondary);
            }

            td:nth-child(4) {
                border-right: 2px solid var(--ct-primary);
                background-color: var(--ct-secondary);
            }

            th {
                border-right: 1px solid #E0E0E0;
                padding-block: 16px;
                padding-inline: 6px;

                > span {
                    display: flex;
                    align-items: flex-start;
                    flex-direction: column;
                    text-align: left;

                    span {
                        font-size: 16px;
                        font-weight: 700;
                        color: var(--ct-heading);
                    }

                    ul {
                        font-size: 13px;
                        font-weight: 400;
                        padding-left: 20px;
                        list-style-type: none;
                        color: #757782;
                        margin-block: 0;

                        li::before {
                            margin-right: 8px;
                            content: "-";
                        }
                    }
                }
            }

            td > span, small {
                line-height: 1;
                display: flex;
                align-items: center;
                flex-direction: column;
                text-align: center;
                row-gap: 8px;
            }

            small {
                row-gap: 0;
            }

            tr:has(+ .red-filled),
            .red-filled {
                border-bottom: none;
            }

            .red-filled th {
                font-size: 14px;
                font-weight: 600;
                text-transform: uppercase;
                color: white;
                border-width: 2px;
                border-color: var(--ct-primary);
                background-color: var(--ct-primary);
                padding-block: 8px;
            }

            .red-bottom-bordered {
                border-bottom: 1px solid var(--ct-primary);
            }

            .red-bottom-bordered th {
                &:first-child {
                    font-size: 20px;
                    font-weight: 700;
                    text-align: left;
                    color: var(--ct-heading);
                }

                &:not(:first-child) {
                    font-size: 15px;
                    font-weight: 500;
                    text-align: center;
                    color: var(--ct-heading);
                }
            }
        }
    }
}
