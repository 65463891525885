.toc-wrapper {
    display: flex;

    @media (--max-mobile) {
        flex-direction: column;
    }

    @media (--min-desktop) {
        position: relative;
        align-items: flex-start;
        column-gap: 80px;

    }

    .toc {
        @media (--max-mobile) {
            @mixin visually-hidden;
        }

        @media (--min-desktop) {
            position: sticky;
            top: 64px;
            flex-shrink: 0;
            width: 220px;
        }
    }

    .toc-list {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        padding-left: 0;
        list-style: none;
        text-align: left;
        margin-block: 0;
        row-gap: 16px;

        a {
            line-height: 1;
            display: inline-flex;
            transition: color var(--DURATION);
            text-decoration: none;
            &:not(.is-active-link, :hover) {
                color: var(--BLACK-5);
            }
            &.is-active-link {
                font-weight: 700;
            }
        }
    }

    .toc-content {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        text-align: left;
        row-gap: 16px;
    }
}

