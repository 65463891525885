.hero {
    display: grid;
    min-height: clamp(20rem, 43.14vi + 9.89rem, 37.5rem);
    background-image: url(../img/hero-bg.svg);
    background-position: center left;
    background-size: cover;
    padding-block: var(--padding-block);
    grid-template-columns: subgrid;
    --grid-column: full-width;

    @media(--max-mobile) {
        margin-top: -68px;
    }

    @media(--min-desktop) {
        margin-top: -108px;
    }

    &__wrapper {
        display: flex;
        grid-column: content;
    }

    h1 {
        font-size: clamp(1.5rem, 3.7vi + 0.63rem, 3rem);
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        margin-block: auto;
    }
}
