@define-mixin hover--opacity {
    @media (hover: hover) {
        & {
            transition: filter .3s;

            &:hover:not(:focus) {
                filter: opacity(.6);
            }
        }
    }
}