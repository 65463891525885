.page-content {
    padding-block: var(--padding-block);

    &__wrapper {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        text-align: left;
        row-gap: var(--padding-block);
    }

    &__text {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        text-align: left;
        row-gap: 1rem;
    }

    h1 {
        font-size: clamp(2rem, 2.47cqi + 1.42rem, 3rem);
        text-transform: uppercase;
    }

    h2 {
        font-size: clamp(1.13rem, 0.92cqi + 0.91rem, 1.5rem);
        text-transform: uppercase;
    }

    h3 {
        font-size: clamp(1rem, 0.62cqi + 0.86rem, 1.25rem);
        font-weight: 600;
    }

    h4 {
        font-size: clamp(1rem, 0.62cqi + 0.86rem, 1.25rem);
        font-weight: 400;
    }

    p:not([class]), li, dt, dd {
        font-weight: 300;
        color: var(--BLACK-4);
    }

    a {
        color: var(--RED);
        @mixin hover--reverse-underline;
        @mixin focus-visible--outline;
    }

    hr {
        width: 100%;
        height: 1px;
        border: none;
        background-color: var(--MID-GRAY);
        margin-block: var(--padding-block);
    }

    ul {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        padding-left: 30px;
        text-align: left;
        margin-block: 0;
        row-gap: 16px;
    }

    dl {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        padding-left: 0;
        text-align: left;
        margin-block: 0;
        row-gap: 16px;
    }

    li::marker {
        color: var(--RED);
    }

    small {
        font-size: 14px;
        font-weight: 300;
        color: var(--BLACK-5);
        margin-block: 16px;
    }
}
