@keyframes slideInFadeIn {
    from {
        transform: translateY(10px);
        filter: opacity(0);
    }
    to {
        transform: translateY(0);
        filter: opacity(1);
    }
}

@keyframes scaleOutFadeIn {
    from {
        transform: scale(1.1);
        filter: opacity(0);
    }
    to {
        transform: scale(1);
        filter: opacity(1);
    }
}
