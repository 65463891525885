.security-wrapper {
    display: grid;
    row-gap: 32px;

    @media(--min-desktop) {
        grid-template-columns: 1fr 300px;
        column-gap: 64px;
    }

    h2 {
        @media(--min-desktop) {
            grid-column: 1/-1;
        }
    }
    .security-text {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        text-align: left;
        row-gap: 16px;

        ul {
            padding-left: 12px;
        }
    }
}
