@font-face {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
    src: url("../fonts/400.woff2") format("woff2");
    font-display: swap;
}

@font-face {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 600;
    src: url("../fonts/600.woff2") format("woff2");
    font-display: swap;
}

@font-face {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 700;
    src: url("../fonts/700.woff2") format("woff2");
    font-display: swap;
}

@font-face {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 300;
    src: url("../fonts/300.woff2") format("woff2");
    font-display: swap;
}

@font-face {
    font-family: 'Proxima Nova';
    font-style: italic;
    font-weight: 300;
    src: url("../fonts/300i.woff2") format("woff2");
    font-display: swap;
}
