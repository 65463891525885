.container-fluid,
.container-standard,
.container-mini {
  width: 100%;
  margin-inline: auto;
}


.container-standard,
.container-mini {
  max-width: var(--containerMaxWidth);
  @media (width <= 639px) {
    padding-inline: var(--containerPaddingHorizontal);
  }
}

.container-mini {
  @media (width >= 1280px) {
    --containerMaxWidth: 970px;
  }
}

.container-fluid {
  --containerMaxWidth: 1920px;
  @media (width >= 1920px) {
    max-width: var(--containerMaxWidth);
  }
}
