.news-content {
    padding-block: var(--padding-block);

    &__wrapper {
        display: grid;
        align-items: start;
        text-align: left;

        @media (--max-mobile) {
            row-gap: 60px;
        }

        @media (--min-desktop) {
            grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
            row-gap: 56px;
            column-gap: 32px;
        }
    }

    h1 {
        font-size: clamp(2rem, 2.47cqi + 1.42rem, 3rem);
        text-transform: uppercase;
        grid-column: 1/-1;
    }

    &__card-img {
        position: relative;
        width: 100%;
        transition: opacity .3s;
        background-color: var(--LIGHT-GRAY);
        background-position: center;
        background-size: cover;
        box-shadow: 0 8px 18px rgba(0, 0, 0, .15);
        aspect-ratio: 16/9;

        &:hover {
            opacity: .7;
        }

        &:has(:focus-visible) {
            outline: 2px solid var(--BLACK);
            outline-offset: 0;
        }
    }

    &__main-card, &__card {
        display: flex;
        flex-direction: column-reverse;
        row-gap: 20px;

        figcaption {
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            row-gap: 12px;
        }

        h2 {
            font-size: 20px;
            color: var(--BLACK);

            a {
                transition: color var(--DURATION);
                text-decoration: none;
                color: currentColor;
                @mixin focus-visible--outline;
            }
        }

        footer {
            font-size: 16px;
            font-weight: 300;
            display: inline-flex;
            align-items: center;
            max-width: 400px;
            color: var(--BLACK-5);
            column-gap: 10px;

            a {
                text-decoration: none;
                color: currentColor;
                @mixin hover--opacity;
                @mixin focus-visible--outline;
                --outline-color: var(--BLACK);
            }
        }
    }

    &__main-card {
        @media (--min-desktop) {
            grid-column: span 2;
        }

        p {
            font-weight: 300;
        }

        h2 {
            @media (--min-desktop) {
                font-size: 27px;
            }
        }
    }

    &__grid {
        display: grid;
        align-items: start;
        width: 100%;
        grid-column: 1 / -1;
        row-gap: 56px;
        column-gap: 32px;
        grid-template-columns: subgrid;
    }

    &__card {

    }

    &__cta {
        display: flex;
        flex-direction: column;
        box-shadow: 0 8px 18px rgba(0, 0, 0, .15);
        padding-block: 32px;
        padding-inline: 32px;
        row-gap: 16px;

        svg {
            margin-inline: auto;
        }

        h2 {
            font-size: 18px;
        }
    }
}
