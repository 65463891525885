.site-footer__address {
    font-size: 16px;
    font-style: normal;
    display: flex;
    flex-direction: column;
    color: white;
    row-gap: 32px;

    dl {
        display: flex;
        flex-direction: column;
        row-gap: 32px;
    }

    div {
        display: flex;
        flex-direction: column;
    }

    dt {
        font-weight: 600;
        margin-bottom: 8px;
    }

    a {
        text-decoration: none;
        color: inherit;
        @mixin hover--opacity;
        @mixin focus-visible--outline;
    }
}
