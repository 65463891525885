.products-preview {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding-block: var(--padding-block);
    row-gap: 64px;

    > header {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        row-gap: 16px;
    }

    h2 {
        font-size: clamp(1.75rem, 1.23vi + 1.46rem, 2.25rem);
        transition: color var(--DURATION);
        text-transform: uppercase;

        &:hover {
            color: var(--RED);
        }

        a {
            text-decoration: none;
            color: currentColor;
            @mixin focus-visible--outline;
        }
    }


    p {
        font-weight: 300;
    }

    &__cards {
        display: flex;

        @media (--max-mobile) {
            flex-direction: column;
            row-gap: 48px;
        }

        @media (--min-desktop) {
            column-gap: 60px;
        }
    }

    &__card {
        display: flex;
        flex-direction: column;
        row-gap: 24px;

        header {
            position: relative;
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            justify-content: flex-end;
            width: 100%;
            padding-block: 16px;
            padding-inline: 32px;

            @media (width <= 767px) {
                height: 175px;
            }

            @media (width >= 768px) {
                height: 250px;
            }

            &::before {
                position: absolute;
                content: "";
                transition: opacity .3s;
                background-color: #cecece;
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
                inset: 0;
            }

            &:hover::before {
                opacity: .6;
            }

            &:has(a:focus-visible) {
                outline: 2px solid var(--BLACK);
                outline-offset: 2px;
            }
        }

        h3 {
            font-size: clamp(1.75rem, 1.23vi + 1.46rem, 2.25rem);
            z-index: 1;
            text-transform: uppercase;
            pointer-events: none;
            color: white;
            text-shadow: 1px 1px #5d5d5d;
        }

        &:nth-child(1) header::before {
            @media (width <= 767px) {
                background-image: url("../img/products-preview/card-1--mobile.webp");
            }

            @media (width >= 768px) {
                background-image: url("../img/products-preview/card-1--desktop.webp");
            }
        }

        &:nth-child(2) header::before {
            @media (width <= 767px) {
                background-image: url("../img/products-preview/card-2--mobile.webp");
            }

            @media (width >= 768px) {
                background-image: url("../img/products-preview/card-2--desktop.webp");
            }
        }
    }
}
