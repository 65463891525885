.article-content {
    width: 100%;

    @media (--max-mobile) {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        --article-content-elements-indent: 24px;
    }

    @media (--min-desktop) {
        --article-content-elements-indent: 40px;
    }

    header {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        margin-bottom: var(--article-content-elements-indent);

        @media (--max-mobile) {
            order: -1;
        }
    }

    h1 {
        font-size: var(--aritcle-content-heading-1, 26px);
        font-weight: 700;
        margin-bottom: 8px;
        color: var(--BLACK);

        @media (--min-desktop) {
            --aritcle-content-heading-1: 35px;
        }
    }

    &__meta {
        font-size: 16px;
        font-weight: 300;
        display: inline-flex;
        align-items: center;
        max-width: 400px;
        color: var(--BLACK-5);

        [aria-hidden="true"] {
            display: inline-flex;
            flex-grow: 1;
            justify-content: center;
            padding-right: 10px;
            padding-left: 10px;
        }

        a {
            text-decoration: none;
            --article-link-color: currentColor;
            @mixin hover--opacity;
            @mixin focus-visible--outline;
            --outline-color: var(--BLACK);
        }
    }

    &__img {
        display: flex;
        width: var(--article-content-img-width, 100%);
        margin-bottom: 40px;
        background-color: var(--LIGHT-GRAY);
        box-shadow: 0 8px 18px rgba(0, 0, 0, .15);

        @media (--min-desktop) {
            float: right;
            max-width: 900px;
            margin-left: 40px;
            --article-content-img-width: 50%;
        }

        img, picture {
            display: block;
            width: 100%;
            height: auto;
        }
    }

    h2 {
        font-size: var(--aritcle-content-heading-2, 20px);
        font-weight: 700;
        margin-top: var(--article-content-elements-indent);
        margin-bottom: 8px;
        color: var(--BLACK);

        @media (--min-desktop) {
            --aritcle-content-heading-2: 22px;
        }
    }

    p, li {
        font-size: 17px;
        font-weight: 300;
        margin-bottom: 8px;
        color: var(--BLACK);
    }

    li {
        position: relative;
        margin-bottom: 14px;
        padding-left: 30px;

        &::before {
            position: absolute;
            top: 9px;
            left: 7px;
            width: 5px;
            height: 5px;
            content: "";
            border-radius: 50%;
            background-color: var(--RED);
        }
    }

    a {
        text-decoration: none;
        color: var(--article-link-color, var(--RED));
        @mixin hover--opacity;
        @mixin focus-visible--outline;
    }

    footer {
        display: flex;
        align-items: center;
        flex-direction: column;

        h2 {
            font-size: var(--aritcle-content-heading-1, 26px);
            font-weight: 700;
            max-width: 1000px;
            margin-bottom: var(--article-content-elements-indent);
            text-align: center;
            text-transform: none;
            color: var(--BLACK);

            @media (--min-desktop) {
                --aritcle-content-heading-1: 35px;
            }
        }
    }

    &__button {
        font-size: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px;
        padding-right: 40px;
        padding-left: 40px;
        cursor: pointer;
        transition: color .3s, background-color .3s;
        border-radius: 0;
        background-color: var(--RED);
        --article-link-color: white;
        @mixin hover--opacity;
        @mixin focus-visible--outline;
        --outline-color: var(--RED);
    }

    hr {
        width: 100%;
        height: 1px;
        border: none;
        background-color: var(--MID-GRAY);
        margin-block: var(--padding-block);
    }

}
