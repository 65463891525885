.btn {
    font-size: 16px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-height: var(--touch-min-size);
    text-decoration: none;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: white;
    border: none;
    border-radius: 0;
    background-color: var(--RED);
    padding-inline: 16px;
    @mixin hover--opacity;
    @mixin focus-visible--outline;
    --outline-color: var(--BLACK);
}
